import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import saudeEBemEstar from '../../assets/images/categories/saude-e-bem-estar.jpeg';
import modaMasculina from '../../assets/images/categories/moda-masculina.jpeg';
import modaFeminina from '../../assets/images/categories/moda-feminina.jpeg';
import fotografia from '../../assets/images/categories/fotografia.jpeg';
import entretenimento from '../../assets/images/categories/entretenimento.jpeg';
import hoteis from '../../assets/images/categories/hoteis.jpeg';
import esporte from '../../assets/images/categories/esportes.jpeg';
import sexyShop from '../../assets/images/categories/sexy-shop.jpeg';
import academia from '../../assets/images/categories/academia.jpeg';
import outros from '../../assets/images/categories/outros.jpeg';

const CategoriesCarousel: React.FC = () => {
	const [cardsCampaigns, setCardsCampaigns] = useState<any[]>([]);

	useEffect(() => {
		setCardsCampaigns([
			{
				id: 1,
				name: 'Saúde e bem estar',
				image: saudeEBemEstar,
			},
			{
				id: 2,
				name: 'Moda masculina',
				image: modaMasculina,
			},
			{
				id: 3,
				name: 'Moda feminina',
				image: modaFeminina,
			},
			{
				id: 4,
				name: 'Fotografia',
				image: fotografia,
			},
			{
				id: 5,
				name: 'Entretenimento',
				image: entretenimento,
			},
			{
				id: 6,
				name: 'Hotéis',
				image: hoteis,
			},
			{
				id: 7,
				name: 'Esportes',
				image: esporte,
			},
			{
				id: 8,
				name: 'Sexy shop',
				image: sexyShop,
			},
			{
				id: 9,
				name: 'Academia',
				image: academia,
			},
			{
				id: 10,
				name: 'Outros',
				image: outros,
			},
		]);
	}, []);

	return (
		<div>
		</div>
	);
};

export default CategoriesCarousel;
