export const registerServiceWorker = () => {
	if ('serviceWorker' in navigator) {
		window.addEventListener('load', function () {
			navigator.serviceWorker.addEventListener('installed', (ev) => {
				if ((ev as any).isUpdate) {
					if (window.confirm('Atualização disponivel. Clique para atualizar')) {
						window.location.reload();
					}
				}
			})
			navigator.serviceWorker
				.register('/sw.js')
				.then(function (registration) {
					// Registration was successful
					console.log(
						'ServiceWorker registration successful with scope: ',
						registration.scope,
					);
				})
				.catch(function (err) {
					// registration failed :(
					console.log('ServiceWorker registration failed: ', err);
				});
		});
	}
};
